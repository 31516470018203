@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: MetropolisThin;
  src: url(./assets/fonts/MetropolisThin.otf);
}

@font-face {
  font-family: MetropolisThinItalic;
  src: url(./assets/fonts/MetropolisThinItalic.otf);
}

@font-face {
  font-family: MetropolisLight;
  src: url(./assets/fonts/MetropolisLight.otf);
}

@font-face {
  font-family: MetropolisLightItalic;
  src: url(./assets/fonts/MetropolisLightItalic.otf);
}

@font-face {
  font-family: MetropolisRegular;
  src: url(./assets/fonts/MetropolisRegular.otf);
}

@font-face {
  font-family: MetropolisRegularItalic;
  src: url(./assets/fonts/MetropolisRegularItalic.otf);
}

@font-face {
  font-family: MetropolisMedium;
  src: url(./assets/fonts/MetropolisMedium.otf);
}

@font-face {
  font-family: MetropolisMediumItalic;
  src: url(./assets/fonts/MetropolisMediumItalic.otf);
}

@font-face {
  font-family: MetropolisSemiBold;
  src: url(./assets/fonts/MetropolisSemiBold.otf);
}

@font-face {
  font-family: MetropolisSemiBoldItalic;
  src: url(./assets/fonts/MetropolisSemiBoldItalic.otf);
}

@font-face {
  font-family: MetropolisBold;
  src: url(./assets/fonts/MetropolisBold.otf);
}

@font-face {
  font-family: MetropolisBoldItalic;
  src: url(./assets/fonts/MetropolisBoldItalic.otf);
}

@font-face {
  font-family: MetropolisExtraBold;
  src: url(./assets/fonts/MetropolisExtraBold.otf);
}

@font-face {
  font-family: MetropolisExtraBoldItalic;
  src: url(./assets/fonts/MetropolisExtraBoldItalic.otf);
}

@font-face {
  font-family: MetropolisBlack;
  src: url(./assets/fonts/MetropolisBlack.otf);
}

@font-face {
  font-family: MetropolisBlackItalic;
  src: url(./assets/fonts/MetropolisBlackItalic.otf);
}